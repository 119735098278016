import React, {Component} from 'react';
import Subpage from './Subpage.js'

class Om extends Component {
	render() {
		return (
			<Subpage>
				<h2>Om</h2>
				<p>Jeg er uddannet rytmisk sanger fra Det Jyske Musikkonservatorium i 2010, og skrev afsluttende kandidatopgave om Sceneoptræden. </p>
				<p>I 2010-12 var jeg assistent på første del af skrivefasen i Kristian Kochs bog “Ud Over Scenekanten”.</p>
				<p>I 2013 startede jeg op som scenecoach og har siden da bl.a været fast tilknyttet som coach v/ORAs Audition Days, lavet masterclasses, workshops, oplæg og coachet diverse bands, solister, musikskoleelever og talentsangere, bl.a hos Jyske Talenter, Herning og Holstebro Musikskole. </p>
				<p>Ud over det er jeg sanger og komponist i mit soloprojekt LHUMA som er elektronisk pop. <a href="http://www.lhumamusic.com" target="_blank">lhumamusic.com</a>.</p>
			</Subpage>
			)
	}
}

export default Om