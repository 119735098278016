import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Animate from 'react-move/Animate';

class Menubar extends Component {
	constructor() {
		super();
		this.state = {
			active: false
		}

	}

	render() {
		return (
				<Animate
					start={{opacity: [0]}}
					update={{
						opacity: [this.state.active ? 1 : 0],
						timing: {duration: 300}
					}}>
					{state => {
						return (
							<div style={{height: '100%', width: '100%', position: 'absolute'}}>
								<svg id="burger" height="40px" width="40px" viewBox="0 0 40 40" onClick={() => this._setMenuActive(!this.state.active)}>
									<ellipse cx="20" cy="20" rx="20" ry="20"/>
									<rect id="burgerline" x="9" y="12" width="22" height="2"></rect>
									<rect id="burgerline" x="9" y="19" width="22" height="2"></rect>
									<rect id="burgerline" x="9" y="26" width="22" height="2"></rect>
								</svg>
								<div id="menubar" onMouseEnter={() => window.innerWidth > 700 ? this._setMenuActive(true) : null} onMouseLeave={() => this._setMenuActive(false)} style={{pointerEvents: window.innerWidth > 700 || this.state.active ? 'auto' : 'none'}}>
									<ul id="mainmenu" style={{opacity: state.opacity}}>
										<li><NavLink exact to="/" onClick={() => window.innerWidth <= 700 ? this._setMenuActive(false) : null}>Forside</NavLink></li>
										<li><NavLink to="/undervisning" onClick={() => window.innerWidth <= 700 ? this._setMenuActive(false) : null}>Undervisning</NavLink></li>
										<li><NavLink to="/om" onClick={() => window.innerWidth <= 700 ? this._setMenuActive(false) : null}>Om</NavLink></li>
										<li><NavLink to="/kontakt" onClick={() => window.innerWidth <= 700 ? this._setMenuActive(false) : null}>Kontakt</NavLink></li>
									</ul>
								</div>
							</div>
						)
					}}
				</Animate>
			)
	}

	_setMenuActive(state) {
		this.setState({
			active: state
		})
	}
}

export default Menubar