const initialState = {
	isOnSubpage: false
}

const app = (state = initialState, action) => {
	switch(action.type) {
		case 'IS_ON_SUBPAGE':
			return {
				...state,
				isOnSubpage: action.isOnSubpage
			}

		default:
			return state
	}
}

export default app