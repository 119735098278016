import React, {Component} from 'react';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';

class Subpage extends Component {
	componentDidMount() {
    	this.props.dispatch({type: 'IS_ON_SUBPAGE', isOnSubpage: true});
    	ReactGA.initialize('UA-137846194-1');
    	ReactGA.pageview(window.location.pathname)
	}

	render() {
		return (
			<div className="scroll-wrapper">
				<div className="subpage page">
					{this.props.children}
				</div>
			</div>
			)
	}
}

export default connect()(Subpage)