import React, {Component} from 'react';
import Subpage from './Subpage.js'

class Kontakt extends Component {
	render() {
		return (
			<Subpage>
				<h2>Kontakt</h2>
				<p>
					<b>Tlf.:</b> 28 11 61 41<br/>
					<b>Mail:</b> <a href="mailto:marielouisegrund@gmail.com">marielouisegrund@gmail.com</a>
				</p>
			</Subpage>
			)
	}
}

export default Kontakt