import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import ReactGA from 'react-ga';

class Frontpage extends Component {
	componentDidMount() {
		this.props.dispatch({type: 'IS_ON_SUBPAGE', isOnSubpage: false})
    	ReactGA.initialize('UA-137846194-1');
		ReactGA.pageview(window.location.pathname);
	}

	render() {
		return (
			<div className="frontpage page">
				<h2>Marie-Louise Grund Petersen</h2>
				<h1>Undervisning i sceneoptræden</h1>
				<p className="quote">Til bands og solister der vil være bedre til at få deres musik ud over scenekanten</p>
				<p className="quote hide-on-mobile">“Marie-Louise giver bands og solister uundværlige råd til hvordan de udvikler sig på scenen”, <span className="quote-originator">Jacob Aarosiin Hansen, ORA</span></p>
				<div className="btn"><Link to="/undervisning">læs mere</Link></div>
			</div>
			)
	}
}

export default connect()(Frontpage)