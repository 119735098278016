import React, { Component } from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import {AnimatedSwitch} from 'react-router-transition';
import ReactGA from 'react-ga';

import Menubar from './js/components/Menubar.js';
import Frontpage from './js/components/Frontpage.js';
import Om from './js/components/Om.js';
import Undervisning from './js/components/Undervisning.js';
import Kontakt from './js/components/Kontakt.js';
import LargeImage from './js/components/LargeImage.js';
import BreadCrumb from './js/components/BreadCrumb.js'

import './App.css';


class App extends Component {
  componentDidMount() {
    ReactGA.initialize('UA-137846194-1');
  }

  render() {
    return (
      <Router>
       <Menubar/>
       <LargeImage/>
       <BreadCrumb/>
       <AnimatedSwitch
          atEnter={{opacity: 0, translate: 10}}
          atLeave={{opacity: 0, translate: -10}}
          atActive={{opacity: 1, translate: 0}}
          mapStyles={styles => ({opacity: styles.opacity, transform: `translateY(${styles.translate}px)`})}
          className="route-wrapper"
        >
          <Route path="/" exact component={Frontpage} />
          <Route path="/om" component={Om} />
          <Route path="/undervisning" component={Undervisning} />
          <Route path="/kontakt" component={Kontakt} />
        </AnimatedSwitch>
      </Router>
    );
  }
}

export default App;
