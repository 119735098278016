import React, {Component} from 'react';
import Subpage from './Subpage.js'

class Undervisning extends Component {
	render() {
		return (
			<Subpage>
				<h2>Undervisning</h2>
				<h3>Hvad er det?</h3>
				<p>Musikeres visuelle udstråling og tilstedværelse under en koncert spiller en afgørende rolle i publikums lydhørhed overfor musikken og samlede oplevelse af koncerten. Mange musikere kommer ubevidst til at stå i vejen for deres egen musik, ved rent visuelt at sende forvirrende signaler til publikum.</p> 
				<p>Undervisning i sceneoptræden giver musikere konkrete visuelle redskaber til at fastholde publikums opmærksomhed, lydhørhed og nysgerrighed på musikken gennem hele koncerten. 
Disse redskaber skaber en fælles forståelsesramme i bandet, som gør det meget nemmere at fremstå som en sammentømret enhed, og hjælper både bands og solister med at blive mere skarpe i, hvordan de bedst understøtter alt det fantastiske potentiale der ligger i musikken.
</p>
				<p>Jeg arbejder ud fra Kristian Kochs COS method, som er viden og effektive teknikker, man som musiker kan gøre brug af for at hjælpe musikken rent og troværdigt ud til publikum. Og i sidste ende give publikum en unik koncertoplevelse.</p>
				<p>Af kerneområder jeg arbejder med kan bl.a nævnes:</p>
				<ul>
					<li>bandmedlemmernes interaktion og kontakt på scenen</li>
					<li>fælles forståelse af musikkens stemninger i bandet / fælles billeder</li>
					<li>opbygning af sætliste, kontraster og visuelle spændingsmomenter i koncerten</li>
					<li>opstilling og fejlfinding</li>
					<li>flow og overgange mellem numrene</li>
					<li>at indtage scenen / bevægelser / øjenkontakt / energi </li>
					<li>en forståelse for publikums vinkel - hvad der skal til for at de føler sig trygge og kan hengive sig til musikken</li>
				</ul>
 				<p>Jeg arbejder med bands og solister, i alle genrer og på alle niveauer.</p>
				<h3>Udtalelser</h3>
				<p className="quote">"Marie-Louise Grund er en stor kapacitet i vores bandcoaching projekt Audition Days. Hun har været en fast del i vores paneler i en del år, og det er der en grund til. Hun rammer nemlig plet som performance coach, og giver de deltagende bands og solister uundværlige råd til hvordan de udvikler sig på scenen. Hendes baggrund som sanger og sangskriver drager de deltagende endvidere stor gavn af.<br/>Marie-Louise er skarp til at formidle sit budskab, er meget omstillingsparat, og ved hvordan hun skal kommunikere til forskelligartede musikere. Lige meget hvilket genre de befinder sig i, eller hvor i karrieren de end måtte være."<br/><span className="quote-originator">- Jacob Aarosiin Hansen, ORA – Organisationen af Rytmiske Amatørmusikere, Audition Days projektansvarlig.</span></p>
				<p className="quote">"Marie-Louise er et skønt alternativ til mig. Hun har en bred forståelse for sceneoptræden, dels i kraft af hendes eget virke som udøvende artist, og som underviser og coach i COS metoden (Credible On Stage). Hun har været med som assistent og sparringspartner da jeg tog de første vigtige skridt i skrivefasen af min bog “Ud over Scenekanten”, og er helt inde i materialet. Hun har skarpe øjne og ører, og en smittende entusiasme og kreativ energi, som løfter alle hun arbejder med. Jeg kan varmt anbefale Marie-Louise til alle musikere, der gerne vil forbedre deres live-performance. "<br/><span className="quote-originator">- Kristian Koch, scenecoach, foredragsholder og forfatter til bogen "Ud Over Scenekanten" </span></p>
				<p className="quote">"Vi har ved flere lejligheder benyttet Marie-Louise til undervisning i stage-performance for vores unge talenter.<br/>Marie-Louise har et godt tag på de unge og det er en stor fornøjelse at se hvordan undervisningen flytter grænser og forbedrer deres selvværd og sceneoptræden."<br/><span className="quote-originator">- Steffen Bastrup Pedersen, Jyske Talenter</span></p>
			</Subpage>
			)
	}
}

export default Undervisning